import { mapActions, mapState } from 'vuex';
import config from 'config';
import { isServer } from '@vue-storefront/core/helpers';

export default {
  name: 'App',
  data () {
    return {
      timer: null
    }
  },
  computed: {
    ...mapState({
      waitSmsSeconds: state => state.ui.waitSmsSeconds,
      canSendSms: state => state.ui.canSendSms
    })
  },
  methods: {
    ...mapActions({
      setCanSendSms: 'ui/setCanSendSms',
      setWaitSmsSeconds: 'ui/setWaitSmsSeconds'
    }),

    waitBeforeSendSms () {
      if (isServer) return
      if (!this.canSendSms) return
      clearInterval(this.timer)
      this.setCanSendSms(false)
      const waitBeforeSendSms = config.sms ? config.sms.waitBeforeSendSms || 60 : 60
      this.setWaitSmsSeconds(waitBeforeSendSms)
      this.timer = setInterval(() => {
        if (this.waitSmsSeconds > 1) {
          this.setWaitSmsSeconds(this.waitSmsSeconds - 1)
        } else {
          clearInterval(this.timer)
          this.setCanSendSms(true)
        }
      }, 1000)
    }
  },
  mounted: function () {
    this.$bus.$on('wait-before-send-sms', this.waitBeforeSendSms)
  },
  beforeDestroy () {
    this.$bus.$off('wait-before-send-sms', this.waitBeforeSendSms)
    clearInterval(this.timer)
  }
}
