import { formatCategoryLink, formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import config from 'config'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import modulesConfig from '$modules/config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { getThumbnailPath, isServer, productThumbnailPath } from '@vue-storefront/core/helpers'
import { htmlDecode } from '@vue-storefront/core/filters'
import { getProductPrice, getProductStock } from './price'
import { prepareRelatedQuery, baseFilterProductsQuery } from 'theme/helpers/overrideQueries';
import rootStore from '@vue-storefront/core/store';
import { getProductQuantity } from 'theme/helpers/product';
import { SearchQuery } from 'storefront-query-builder';
import Fetch from '@vue-storefront/core/lib/isomorphic-fetch'

export * from './price'

export function createSmoothscroll (from = 0, to = 0, speed = 15) {
  let currentDelta = from > to ? from - to : to - from

  const height = document.body.scrollHeight || document.documentElement.scrollHeight

  document.body.setAttribute('style', `min-height: ${height}px`)

  const smoothscroll = () => {
    if (Math.abs(currentDelta) < speed) {
      document.body.setAttribute('style', '')

      return
    }

    currentDelta = currentDelta - currentDelta / speed

    window.requestAnimationFrame(smoothscroll)

    const scrollTo = from > to ? to + currentDelta : to - currentDelta

    window.scrollTo(0, scrollTo);
  }
  return smoothscroll()
}

export function checkWebpSupport (bannersToTransform, isWebpSupported) {
  // In SSR it is not easily known whether webp image is supported by web browser or not.
  // Empty string also cannot be used here, because empty string evaluates to url('')
  // and it is resolved as the base URL (the same as our Homepage), so as a consequence
  // Homepage was requested again.
  // To fix that case, dummy empty data URI is provided just to prevent any unnecessary
  // requests.
  // --- see https://github.com/DivanteLtd/vsf-capybara/issues/168
  const theSmallestDummyImage = 'data:,'
  return bannersToTransform?.map(banner => Object.assign(
    {},
    banner,
    { image: isServer ? theSmallestDummyImage : isWebpSupported ? banner.image.webp : banner.image.fallback }
  ))
}

export function getTopLevelCategories (categoryList) {
  // Display only the root level (level = 1 => Default Category), categoriesDynamicPrefetchLevel = 2 by default
  const categoryLevel = config.entities.category.categoriesDynamicPrefetchLevel >= 0
    ? config.entities.category.categoriesDynamicPrefetchLevel
    : 2

  return categoryList.filter(
    category =>
      category.level === categoryLevel &&
      category.is_active &&
      category.include_in_menu
  )
}

export function getLabelDiscount (product) {
  return product?.varus_special_price_discount || ''
}

export function getDiscountDate (product) {
  const date = product?.special_price_to_date || 0

  if (date === 0) {
    return date
  } else {
    const dateParts = date.split('-')
    const formatDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]))

    return {
      date: {
        year: dateParts[0],
        month: dateParts[1],
        day: dateParts[2]
      },
      formatDate: formatDate
    }
  }
}

const getAttributeValue = (product, code) => (
  product[config.attributesCodes[code]]
    ? +product[config.attributesCodes[code]]
    : product[config.attributesCodes[code]]
)

export function prepareCategoryProduct (product, index = null) {
  const result = {
    ...product,
    title: htmlDecode(product.name),
    image: getThumbnailPath(
      productThumbnailPath(product),
      config.products.thumbnails.width,
      config.products.thumbnails.height
    ),
    link: formatProductLink(product, currentStoreView().storeCode),
    price: getProductPrice(product),
    rating: {
      max: 5,
      score: 5
    },
    stock: getProductStock(product),
    quantityText: getProductQuantity(product),
    labelDiscount: getLabelDiscount(product),
    volume: product?.volume || '',
    qtyUnit: product[config.attributesCodes.qtyUnit],
    qtyUnitStep: getAttributeValue(product, 'qtyUnitStep'),
    minSalableQty: getAttributeValue(product, 'minSalableQty'),
    pimBrandId: product[config.attributesCodes.pimBrandId],
    varusPerfect: product[config.attributesCodes.varusPerfect],
    qtyStepRecommend: getAttributeValue(product, 'qtyStepRecommend'),
    forNewPost: product?.forNewPost,
    special_price_discount: product.sqpp?.special_price_discount,
    special_price_to_date: product.sqpp?.special_price_to_date
  }

  if (index !== null) result.index = index

  return result
}

const stepAttributes = [
  config.attributesCodes.qtyUnit,
  config.attributesCodes.qtyUnitStep,
  config.attributesCodes.minSalableQty,
  config.attributesCodes.pimBrandId,
  config.attributesCodes.qtyStepRecommend
];

export function prepareProductStepValues (products) {
  const result = [];

  if (!products || products.length === 0) return result;

  for (const product of products) {
    if (!product.packingtype) continue;

    for (const stepAttribute of stepAttributes) {
      if (product[stepAttribute] && !result.includes(product[stepAttribute])) {
        result.push(product[stepAttribute]);
      }
    }
  }

  return result;
}

export function prepareCategoryMenuItem (category, remap = null) {
  if (!category || category.isPrepared) {
    return category;
  }

  const items = (category.children_data || [])
    .map((item) => prepareCategoryMenuItem(item, remap))
    .sort((a, b) => a.position - b.position)

  const preparedCategory = {
    ...category,
    id: category.id,
    isPrepared: true,
    name: category.name,
    link: formatCategoryLink(category),
    count: '',
    position: category.position,
    path: category.path,
    items
  }

  return remap ? remap(preparedCategory) : preparedCategory;
}

export function buildFilterProductsQuery (currentCategory, chosenFilters = {}, defaultFilters = null, queryText = '', toSkipCat = [], shopId = null) {
  const filters = currentCategory?.filterattributes ? currentCategory.filterattributes?.split(',') : config.products.defaultFilters

  if (!filters.includes('pim_brand_id')) {
    filters.unshift('pim_brand_id')
  }
  let filterQr = baseFilterProductsQuery(currentCategory, defaultFilters == null ? filters : defaultFilters, toSkipCat)

  if (queryText.length) {
    filterQr.setSearchText(queryText)
  }

  // add chosen filters
  for (const code of Object.keys(chosenFilters)) {
    let filter = chosenFilters[code]
    if (Array.isArray(filter) && filter[0].type === config.attributes.filterIsPromo.type) {
      filter = config.attributes.filterIsPromo.type
    }

    const attributeCode = Array.isArray(filter) ? filter[0].attribute_code : filter.attribute_code
    if (Array.isArray(filter) && attributeCode !== 'price') {
      const values = filter.map(filter => filter.id)
      filterQr = filterQr.applyFilter({ key: attributeCode, value: { 'in': values }, scope: 'catalog' })
    } else if (filter === config.attributes.filterIsPromo.type) {
      filterQr = filterQr.applyFilter({ key: filter, value: { 'in': shopId ? [shopId] : null }, scope: 'default' })
    } else if (attributeCode !== 'price') {
      filterQr = filterQr.applyFilter({ key: attributeCode, value: { 'eq': filter.id }, scope: 'catalog' })
    } else if (attributeCode === 'price') {
    } else { // multi should be possible filter here?
      const rangeqr = {}
      const filterValues = Array.isArray(filter) ? filter : [filter]
      filterValues.forEach(singleFilter => {
        if (singleFilter.from) rangeqr['gte'] = singleFilter.from
        if (singleFilter.to) rangeqr['lte'] = singleFilter.to
      })
      filterQr = filterQr.applyFilter({ key: attributeCode, value: rangeqr, scope: 'catalog' })
    }
  }
  return filterQr
}

export function buildFilterAvailableQuery (currentCategory, defaultFilters = null, toSkipCat = []) {
  const filters = currentCategory?.filterattributes ? currentCategory.filterattributes?.split(',') : config.products.defaultFilters

  if (!filters.includes('pim_brand_id')) {
    filters.unshift('pim_brand_id')
  }

  return baseFilterProductsQuery(currentCategory, defaultFilters == null ? filters : defaultFilters, toSkipCat)
}

export function baseFilterProductsQueryNoProduct (filters = []) { // TODO add aggregation of color_options and size_options fields
  let searchProductQuery = new SearchQuery()
  searchProductQuery = searchProductQuery
    .applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
    .applyFilter({ key: 'status', value: { 'in': [0, 1] } }) /* 2 = disabled, 4 = out of stock */

  if (config.products.listOutOfStockProducts === false) {
    searchProductQuery = searchProductQuery.applyFilter({ key: 'stock.is_in_stock', value: { 'eq': true } })
  }
  // Add available catalog filters
  for (const attrToFilter of filters) {
    searchProductQuery = searchProductQuery.addAvailableFilter({ field: attrToFilter, scope: 'catalog' })
  }

  return searchProductQuery
}

export function buildFilterProductsQuerySearch (chosenFilters = {}, defaultFilters = null, queryText = '') {
  const filters = config.products.defaultFilters
  const filterQr = baseFilterProductsQueryNoProduct(defaultFilters === null ? filters : defaultFilters)
  if (queryText.length) {
    filterQr.setSearchText(queryText)
  }
  // add chosen filters
  for (const code of Object.keys(chosenFilters)) {
    let filter = chosenFilters[code]
    if (Array.isArray(filter) && filter[0].type === config.attributes.filterIsPromo.type) {
      filter = config.attributes.filterIsPromo.type
    }
    const attributeCode = Array.isArray(filter) ? filter[0].attribute_code : filter.attribute_code
    if (Array.isArray(filter) && attributeCode !== 'price') {
      const values = filter.map(filter => filter.id)
      filterQr.applyFilter({ key: attributeCode, value: { 'in': values }, scope: 'catalog' })
    } else if (filter === config.attributes.filterIsPromo.type) {
      filterQr.applyFilter({ key: filter, value: { 'in': null }, scope: 'default' })
    } else if (attributeCode !== 'price') {
      filterQr.applyFilter({ key: attributeCode, value: { 'eq': filter.id }, scope: 'catalog' })
    } else if (attributeCode === 'price') {
    } else { // multi should be possible filter here?
      const rangeqr = {}
      const filterValues = Array.isArray(filter) ? filter : [filter]
      filterValues.forEach(singleFilter => {
        if (singleFilter.from) rangeqr['gte'] = singleFilter.from
        if (singleFilter.to) rangeqr['lte'] = singleFilter.to
      })
      filterQr.applyFilter({ key: attributeCode, value: rangeqr, scope: 'catalog' })
    }
  }
  return filterQr
}

export function getEsputnikProductIds (selector) {
  const elements = document.querySelectorAll(selector);
  const productIds = [];

  Array.from(elements).forEach(element => {
    productIds.push(Number(element.getAttribute('data-product-id')));
  });

  return productIds
}

export function getEsputnikUrl (selector) {
  const elem = document.querySelector(selector);
  const url = elem?.getAttribute('data-product-href');

  return url ? new URL(url).search : '';
}

export async function getAndStoreRelatedProducts (
  widgetId = '',
  relatedProductsKey = '',
  size = config?.entities?.product?.carouselSize,
  onlyInStock = false
) {
  const sku = getEsputnikProductIds('#' + widgetId + ' .recommend-product-id') || []

  if (!sku.length) {
    return
  }
  const querySize = sku.length < size ? sku.length : size;
  const relatedProductsQuery = prepareRelatedQuery('sku', sku);

  const response = await rootStore.dispatch('product/findRelatedProducts', {
    query: relatedProductsQuery,
    size: querySize,
    onlyInStock
  });

  if (response) {
    rootStore.dispatch('product/related', {
      key: relatedProductsKey,
      items: response.items
    });
  }
}

export async function loadRelatedProducts (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  widgetId = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  size = config?.entities?.product?.carouselSize,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onlyInStock = false
) {
  return []
  // const sku = getEsputnikProductIds('#' + widgetId + ' .recommend-product-id') || []
  // if (!sku.length) {
  //   return
  // }
  //
  // const querySize = sku.length < size ? sku.length : size;
  // const relatedProductsQuery = prepareRelatedQuery('sku', sku);
  //
  // const response = await rootStore.dispatch('product/findProducts', {
  //   query: relatedProductsQuery,
  //   size: querySize,
  //   prefetchGroupProducts: false,
  //   updateState: false,
  //   includeFields: modulesConfig.smallProduct.includeFields,
  //   excludeFields: modulesConfig.smallProduct.excludeFields,
  //   skipLoadOptions: true,
  //   onlyInStock
  // });
  //
  // return response.items || []
}

export function getImagePath (identifier: string, width: number, height: number, type: string): string {
  let baseUrl = config.images.baseUrl;
  baseUrl = baseUrl.slice(-1) !== '/' ? baseUrl : baseUrl.slice(0, baseUrl.length - 1);

  return [baseUrl, type, width.toString(), height.toString(), identifier].join('/')
}

export function getProductImagePath (sku: string, width: number, height: number): string {
  return getImagePath(sku, width, height, 'product')
}

export function getProductCarouselImagePath (sku: string, width: number, height: number, index: number, type = 'carousel'): string {
  let baseUrl = config.images.baseUrl;
  baseUrl = baseUrl.slice(-1) !== '/' ? baseUrl : baseUrl.slice(0, baseUrl.length - 1);

  return [baseUrl, type, width.toString(), height.toString(), sku, index].join('/')
}

export function getProductCarouselThumbPath (sku: string, index: number, width = 80, height = 80, type = 'carousel'): string {
  let baseUrl = config.images.baseUrl;
  baseUrl = baseUrl.slice(-1) !== '/' ? baseUrl : baseUrl.slice(0, baseUrl.length - 1);

  return [baseUrl, type, width.toString(), height.toString(), sku, index].join('/')
}

export function getCategoryImagePath (id: string, width = 72, height = 72): string {
  return getImagePath(id, width, height, 'category')
}

export function getBrandImagePath (id: string, width = 200, height = 85): string {
  return getImagePath(id, width, height, 'brand')
}

export function getProductAttributeImagePath (id: string, width = 60, height = 60): string {
  return getImagePath(id, width, height, 'attribute')
}

export async function getImageByFetch (id: string) {
  const getUrl = getBrandImagePath(id)

  return Fetch(getUrl)
}

export async function getNonProductCategoryIds () {
  const {
    varusPerfectCategoryId,
    specialOffersCategoryId
  } = await rootStore.dispatch('category-extension/specialCategoriesIds', null, { root: true });

  return [varusPerfectCategoryId, specialOffersCategoryId];
}

export function getRelatedCategories (relatedProducts = [], categories = []) {
  if (!relatedProducts.length || !categories) {
    return []
  }
  const result = []

  categories.forEach(e => {
    let products = relatedProducts.filter(product => product.category_ids && product.category_ids.includes(e.id))
    products = products.slice(0, config.entities.product.carouselSize)
    if (products?.length) {
      result.push({ id: e.id, length: products.length, name: e.name, products })
    }
  })

  return result.sort((a, b) => { return parseInt(b.length) - parseInt(a.length) })
}

export const asyncDelay = (ms: number): Promise<void> => new Promise((resolve) => {
  if (!ms) {
    resolve();
    return;
  }

  setTimeout(() => resolve(), ms);
})

const PIM_EMPTY_FIELD = '-';
export const checkOnEmpty = (value) => {
  return Boolean(value === '' || value === undefined || value === null || value === PIM_EMPTY_FIELD);
}

export const transformToNumber = (value) => {
  if (checkOnEmpty(value)) return 0;
  if (typeof value === 'number') return value;

  return Number(value);
}

export const formatCurrencyWithSpace = (num) => {
  const removeSpaces = parseFloat(num.replace(/\s/g, '')).toFixed(2)
  const locale = config.ua?.i18n?.defaultLocale || 'uk-UA'

  return new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(+removeSpaces).replace(',', '.')
}
