import Vue from 'vue'
import { MutationTree } from 'vuex'
import { BrandState } from '../types';
import * as types from './mutation-types'

const mutations: MutationTree<BrandState> = {
  [types.SPECIAL_OFFERS_SET_CATEGORIES] (state, categories) {
    state.categories = categories;
  },
  [types.SET_BRAND_CATEGORY] (state, category) {
    state.currentBrand = category;
  },
  [types.ADD_PRODUCTS] (state, products) {
    state.products = products;
  },
  [types.UPDATE_PRODUCTS] (state, products) {
    state.products = [...state.products, ...products];
  },
  [types.SET_CATEGORY_FILTERS] (state, { category, filters }) {
    Vue.set(state.filtersMap, category.id, filters)
  },
  [types.SPECIAL_OFFERS_SET_CURRENT_CATEGORY] (state, category) {
    state.currentCategory = category
  },
  [types.SPECIAL_OFFERS_SET_SEARCH_PRODUCTS_STATS] (state, stats = {}) {
    state.searchProductsStats = stats
  },
  [types.SPECIAL_OFFERS_SET_CATEGORIES_IS_LOADING] (state, isLoading) {
    state.categoriesIsLoading = isLoading
  },
  [types.SET_PRODUCTS_IS_LOADING] (state, isLoading) {
    state.productsIsLoading = isLoading
  },
  [types.AVAILABLE_PRODUCTS] (state, available) {
    state.availableProducts = available;
  },
  [types.SET_IS_ADDITIONAL_LOADING] (state, isAdditionalLoading) {
    state.isAdditionalLoading = isAdditionalLoading
  },
  [types.SET_PROMOTION_BANNERS] (state, banners) {
    state.promotionBanners = banners || []
  }
};

export default mutations
