import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { BrandState } from '../types';
import * as types from './mutation-types'
import { getCurrentShopId } from 'theme/store/checkout/helpers';
import { buildFilterAvailableQuery, buildFilterProductsQuery, calcQueryStart } from '../helpers';
import {
  SET_PRODUCT_AGGREGATION
} from 'theme/store/category-extension/store/mutation-types'
import {
  CATEGORY_ADD_CATEGORIES,
  CATEGORY_ADD_NOT_FOUND_CATEGORY_IDS
} from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { cloneDeep, omit } from 'lodash'
import { PAGE_SIZE } from '../config';
import config from 'config';
import { router } from '@vue-storefront/core/app';
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver';
import { Category } from '@vue-storefront/core/modules/catalog-next/types/Category';
import Vue from 'vue';
import { BrandService } from '$modules/brand/data-resolver/CategoryService';
import { isServer } from '@vue-storefront/core/helpers';
import modulesConfig from '$modules/config';
import { getSearchOptionsFromRouteParams } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers';
import { SearchQuery } from 'storefront-query-builder';
import { aggregationRemap } from 'theme/helpers/product';
import { applyFilterFromToByDatetime, setRequestCacheTags } from 'theme/store/banner/helpers';
import { BannerService } from 'theme/store/banner/data-resolver/BannerService';
import { Logger } from '@vue-storefront/core/lib/logger'
import { checkIfDateExpired } from 'theme/helpers/date';

const actions: ActionTree<BrandState, RootState> = {
  async loadBrandCategory ({ dispatch, commit }, { route }) {
    const filters = getSearchOptionsFromRouteParams(route.params);
    const category = await dispatch('category-next/loadCategory', { filters, size: 1 }, { root: true })
    commit(types.SET_BRAND_CATEGORY, category)
    return category
  },
  async loadCategory ({ dispatch }, { route }) {
    const { cat } = route.query
    return dispatch('category-next/loadCategory', { filters: { id: cat } }, { root: true })
  },
  async loadBrandProducts ({
    dispatch,
    getters,
    commit,
    state
  }, {
    route,
    category,
    start = 0
  } = {}) {
    if (!category) return;

    const shopId = await getCurrentShopId()
    if (!shopId) return

    if (!isServer) {
      await dispatch('loadCategoryFilters', category);
    }

    const { id: categoryId } = category;
    const { query: routerQuery } = route;

    const mappedFilters = getters.getFiltersMap[categoryId];
    const searchQuery = getters.getCurrentFiltersFrom(routerQuery, mappedFilters);
    const { filters: chosenFilters, sort, stock_shop } = searchQuery;
    const hasPromoFilter = routerQuery.hasOwnProperty([config.attributes.filterIsPromo.type]);
    const onlyInStock = stock_shop;
    const filterQuery = buildFilterProductsQuery({ currentBrand: state.currentBrand, chosenFilters, currentCategory: category, shopId, route });
    const filterAvailableQuery = buildFilterAvailableQuery({ currentBrand: state.currentBrand, currentCategory: category });

    if (routerQuery.cat) {
      filterQuery.applyFilter({ key: 'category_ids', value: { 'in': category.id } })
    }
    if (hasPromoFilter) {
      filterQuery.applyFilter(
        {
          key: 'has_promotion_in_stores',
          value: { 'in': [shopId] },
          scope: 'catalog'
        })
    }

    const areFiltersEmpty = !Object.keys(routerQuery).length;
    start = calcQueryStart({ areFiltersEmpty, routerQuery, pageSize: PAGE_SIZE });

    const sortOrder = sort || routerQuery.sort || 'popularity:desc';
    filterQuery.addAvailableFilter({ field: 'promotion_banner_ids', scope: 'catalog' })

    const { items, aggregations, attributeMetadata, perPage, total } = await dispatch('product/findProducts', {
      query: filterQuery,
      sort: sortOrder,
      start,
      size: PAGE_SIZE,
      includeFields: modulesConfig.smallProduct.includeFields,
      excludeFields: modulesConfig.smallProduct.excludeFields,
      onlyInStock: onlyInStock
    }, { root: true });

    const productsInStock = await dispatch('product/categoryProductsInStock', {
      query: filterAvailableQuery
    }, { root: true })

    commit(types.AVAILABLE_PRODUCTS, productsInStock)

    const isAdditionalLoading = state.isAdditionalLoading

    commit(isAdditionalLoading ? types.UPDATE_PRODUCTS : types.ADD_PRODUCTS, items);
    await dispatch('loadBanners', aggregations)
    dispatch('ui/setPagingLoading', false, { root: true })

    if (!isServer) {
      await dispatch('loadAvailableFiltersFrom', {
        aggregations,
        attributeMetadata,
        category,
        filters: chosenFilters
      });
    }

    if (!isServer) {
      commit(`category-extension/${SET_PRODUCT_AGGREGATION}`, aggregations, { root: true });
      commit(types.SET_PRODUCTS_IS_LOADING, false);
    }
    commit(types.SPECIAL_OFFERS_SET_SEARCH_PRODUCTS_STATS, { perPage, start, total })

    return items;
  },
  async loadBrandCategories ({ commit, dispatch, state }) {
    const shopId = await getCurrentShopId()
    const category = state.currentBrand;
    const filterQr = buildFilterProductsQuery({ currentCategory: category, currentBrand: state.currentBrand, shopId })

    const { aggregations } = await quickSearchByQuery({
      query: filterQr,
      excludeFields: ['*']
    })

    const categoryIds = aggregations.agg_terms_category_ids.buckets
    const categoryIdsArray = categoryIds.map(elem => elem.key)
    const categoryArray = await dispatch('getCategoriesByChunk', { categoryIdsArray })

    const categories = categoryArray.filter(category => {
      return categoryIds.some(categoryId => {
        if (category.id === categoryId.key) {
          category.countProduct = categoryId.doc_count
        }
        return category.id === categoryId.key
      })
    }).filter(cat => cat.id !== 14779 && cat.id !== 2 && cat.id !== 470 && cat.parent_id !== 470)

    if (!isServer) {
      commit(types.SPECIAL_OFFERS_SET_CATEGORIES, categories)
      commit(types.SPECIAL_OFFERS_SET_CATEGORIES_IS_LOADING, false)
    }
  },
  async loadCategoryFilters ({ dispatch, state }, category) {
    const shopId = await getCurrentShopId()
    const filterQr = buildFilterProductsQuery({ currentCategory: category, currentBrand: state.currentBrand, shopId })

    const { aggregations, attributeMetadata } = await quickSearchByQuery({
      query: filterQr,
      excludeFields: ['*']
    })

    await dispatch('loadAvailableFiltersFrom', {
      aggregations: aggregationRemap(aggregations), attributeMetadata: attributeMetadata, category
    })
  },
  async loadAvailableFiltersFrom ({ commit, getters, dispatch }, { aggregations, attributeMetadata, category, filters = {} }) {
    await dispatch('attribute/loadCategoryAttributes', { attributeMetadata }, { root: true })
    const aggregationFilters = getters.getAvailableFiltersFrom(aggregations, category)
    const categoryMappedFilters = getters.getFiltersMap[category.id]
    let resultFilters = aggregationFilters
    const filtersKeys = Object.keys(filters)
    if (categoryMappedFilters && filtersKeys.length) {
      resultFilters = Object.assign(cloneDeep(categoryMappedFilters), cloneDeep(omit(aggregationFilters, filtersKeys)))
    }

    commit(types.SET_CATEGORY_FILTERS, { category, filters: resultFilters })
  },
  changeRouterFilterParameters (context, query) {
    return new Promise<void>((resolve, reject) => {
      router.push(
        { query: query },
        () => resolve(),
        (err) => reject(err)
      );
    });
  },
  async getCategoriesByChunk ({ dispatch }, { chunkSize = 200, categoryIdsArray = [] }) {
    if (!categoryIdsArray || !categoryIdsArray.length) return []

    const chunks = Math.ceil(categoryIdsArray.length / chunkSize)

    const toPromise = []

    for (let i = 0; i < chunks; i++) {
      const filter = { id: categoryIdsArray.slice(i * chunkSize, (i + 1) * chunkSize) }

      toPromise.push(
        dispatch('loadCategories', { filters: filter })
      )
    }

    const categoryChunk = await Promise.all(toPromise)
    const categoryArray = categoryChunk.reduce((a, c) => a.concat(c), [])

    return categoryArray
  },
  async loadCategories ({ commit, rootGetters }, categorySearchOptions: DataResolver.CategorySearchOptions): Promise<Category[]> {
    const searchingByIds = !(!categorySearchOptions || !categorySearchOptions.filters || !categorySearchOptions.filters.id)
    const searchedIds: string[] = searchingByIds ? [...categorySearchOptions.filters.id].map(String) : []
    const loadedCategories: Category[] = []
    if (searchingByIds && !categorySearchOptions.reloadAll) { // removing from search query already loaded categories, they are added to returned results
      for (const [categoryId, category] of Object.entries(rootGetters['category-next/getCategoriesMap'])) {
        if (searchedIds.includes(categoryId)) {
          loadedCategories.push(category as Category)
        }
      }
      categorySearchOptions.filters.id = searchedIds.filter(categoryId => !rootGetters['category-next/getCategoriesMap'][categoryId] && !rootGetters['category-next/getNotFoundCategoryIds'].includes(categoryId))
    }
    if (!searchingByIds || categorySearchOptions.filters.id.length) {
      categorySearchOptions.filters = Object.assign(cloneDeep(config.entities.category.filterFields), categorySearchOptions.filters ? cloneDeep(categorySearchOptions.filters) : {})
      const categories = await BrandService.getCategories(categorySearchOptions)
      if (Vue.prototype.$cacheTags) {
        categories.forEach(category => {
          Vue.prototype.$cacheTags.add(`C${category.id}`)
        })
      }
      const notFoundCategories = searchedIds.filter(categoryId => !categories.some(cat => cat.id === parseInt(categoryId) || cat.id === categoryId))

      if (!isServer) {
        commit(`category-next/${CATEGORY_ADD_CATEGORIES}`, categories, { root: true })
      }
      commit(`category-next/${CATEGORY_ADD_NOT_FOUND_CATEGORY_IDS}`, notFoundCategories, { root: true })
      return [...loadedCategories, ...categories]
    }
    return loadedCategories
  },
  async setCurrentCategory ({ commit }, category) {
    commit(types.SPECIAL_OFFERS_SET_CURRENT_CATEGORY, category)
  },
  async setProductsLoading ({ commit }, isLoading) {
    commit(types.SET_PRODUCTS_IS_LOADING, isLoading)
  },
  async loadMore ({ commit }, value) {
    commit(types.SET_IS_ADDITIONAL_LOADING, value);
  },
  async loadBanners ({ dispatch }, aggregations) {
    const ids = aggregations['agg_terms_promotion_banner_ids.keyword'].buckets.map(elem => elem.key)

    dispatch('updatePromotionBanners', { promotionBannerIds: ids })
  },
  async updatePromotionBanners ({ commit, state }, { promotionBannerIds }) {
    if (!promotionBannerIds || !promotionBannerIds.length) {
      commit(types.SET_PROMOTION_BANNERS, [])
      return;
    }

    const banners = (state.promotionBanners || []).map(i => `${i.id}`)
    const bannersSet = new Set(banners);
    const promotionBannerIdsSet = new Set(promotionBannerIds);
    const hasDiff = [...bannersSet].some(x => !promotionBannerIdsSet.has(x)) || [...promotionBannerIdsSet].some(x => !bannersSet.has(x.toString()));

    if (!hasDiff) return

    try {
      const query = new SearchQuery();
      applyFilterFromToByDatetime(query)
      query.applyFilter({ key: 'id', value: { 'in': promotionBannerIds } })
      const { items } = await BannerService.getBanners({ query })
      const filteredItems = items.filter(item => !checkIfDateExpired(item?.datetime_to))
      setRequestCacheTags(filteredItems)
      commit(types.SET_PROMOTION_BANNERS, filteredItems)
    } catch (err) {
      Logger.debug('Unable to load Brand Promo Banners' + err)()
    }
  }
}
export default actions
