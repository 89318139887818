import Vue from 'vue'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { entityKeyName } from '@vue-storefront/core/lib/store/entities'
import rootStore from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n';
import chunk from 'lodash/chunk'
import trim from 'lodash/trim'
import toString from 'lodash/toString'
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers'
import RootState from '@vue-storefront/core/types/RootState';
import CategoryState from '../types/CategoryState'
import { currentStoreView, localizedDispatcherRoute } from '@vue-storefront/core/lib/multistore'
import { Logger } from '@vue-storefront/core/lib/logger'
import { isServer } from '@vue-storefront/core/helpers';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import createCategoryListQuery from '@vue-storefront/core/modules/catalog/helpers/createCategoryListQuery';
import { transformCategoryUrl } from '@vue-storefront/core/modules/url/helpers/transformUrl';
import { getCurrentShopId } from 'theme/store/checkout/helpers';
import { buildFilterAvailableQuery, buildFilterProductsQuery } from 'theme/helpers';
import modulesConfig from '$modules/config';
import { products, entities, attributes } from 'config';
import {
  CATEGORY_SET_SEARCH_PRODUCTS_STATS
} from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import { SET_PRODUCT_AGGREGATION } from 'theme/store/category-extension/store/mutation-types';
import { router } from '@vue-storefront/core/app';
import { _prepareCategoryPathIds } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers'
import { parseCategoryPath } from '@vue-storefront/core/modules/breadcrumbs/helpers'

const actions: ActionTree<CategoryState, RootState> = {
  /**
   * Reset current category and path
   * @param {Object} context
   */
  reset (context) {
    context.commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, [])
    rootStore.dispatch('stock/clearCache')
    EventBus.$emit('category-after-reset', { })
  },
  /**
   * Load categories within specified parent
   * @param {Object} commit promise
   * @param state
   * @param dispatch
   * @param {Object} parent parent category
   * @param key
   * @param value
   * @param level
   * @param onlyActive
   * @param onlyNotEmpty
   * @param size
   * @param start
   * @param sort
   * @param includeFields
   * @param excludeFields
   * @param skipCache
   * @param updateState
   * @param includesInMenu
   */
  async list ({
    commit,
    state,
    dispatch
  }, {
    parent = null,
    key = null,
    value = null,
    level = null,
    onlyActive = true,
    onlyNotEmpty = false,
    size = 4000,
    start = 0,
    sort = 'position:asc',
    includeFields = entities.optimize ? entities.category.includeFields : null,
    excludeFields = entities.optimize ? entities.category.excludeFields : null,
    skipCache = false,
    updateState = true,
    includesInMenu = false
  }) {
    const { searchQuery, isCustomizedQuery } = createCategoryListQuery({ parent, level, key, value, onlyActive, onlyNotEmpty })
    const shouldLoadCategories = skipCache || ((!state.list || state.list.length === 0) || isCustomizedQuery)

    let customSearchQuery = searchQuery

    if (includesInMenu) {
      customSearchQuery = customSearchQuery.applyFilter({ key: 'include_in_menu', value: { 'eq': 1 } })
    }

    if (shouldLoadCategories) {
      const resp = await quickSearchByQuery({ entityType: 'category', query: customSearchQuery, sort, size, start, includeFields, excludeFields })

      if (updateState) {
        await dispatch('registerCategoryMapping', { categories: resp.items })

        commit(types.CATEGORY_UPD_CATEGORIES, { ...resp, includeFields, excludeFields })
        commit(types.SET_CATEGORIES_IS_LOADING, false)
        EventBus.$emit('category-after-list', { query: customSearchQuery, sort, size, start, list: resp })
      }

      return resp
    }

    const list = { items: state.list, total: state.list.length }

    if (updateState) {
      EventBus.$emit('category-after-list', { query: searchQuery, sort, size, start, list })
    }

    return list
  },
  async registerCategoryMapping ({ dispatch }, { categories }) {
    const { storeCode } = currentStoreView()
    for (const category of categories) {
      if (category.url_path) {
        await dispatch('url/registerMapping', {
          url: localizedDispatcherRoute(category.url_path, storeCode),
          routeData: transformCategoryUrl(category)
        }, { root: true })
      }
    }
  },

  /**
   * Load category object by specific field - using local storage/indexed Db
   * loadCategories() should be called at first!
   * @param {Object} commit
   * @param {String} key
   * @param {String} value
   * @param {Bool} setCurrentCategory default=true and means that state.current_category is set to the one loaded
   */
  single (context, { key, value, setCurrentCategoryPath = true, populateRequestCacheTags = true, skipCache = false }) {
    const state = context.state
    const commit = context.commit
    const dispatch = context.dispatch

    return new Promise((resolve, reject) => {
      const fetchCat = ({ key, value }) => {
        if (key !== 'id' || value >= entities.category.categoriesRootCategoryId/* root category */) {
          context.dispatch('list', { key: key, value: value }).then(res => {
            if (res && res.items && res.items.length) {
              setcat(null, res.items[0]) // eslint-disable-line @typescript-eslint/no-use-before-define
            } else {
              reject(new Error('Category query returned empty result ' + key + ' = ' + value))
            }
          }).catch(reject)
        } else {
          reject(new Error('Category query returned empty result ' + key + ' = ' + value))
        }
      }
      const setcat = (error, mainCategory) => {
        if (!mainCategory) {
          fetchCat({ key, value })
          return
        }
        if (error) {
          Logger.error(error)()
          reject(error)
        }

        if (populateRequestCacheTags && mainCategory && Vue.prototype.$cacheTags) {
          Vue.prototype.$cacheTags.add(`C${mainCategory.id}`)
        }
        if (setCurrentCategoryPath) {
          const currentPath = []
          const recurCatFinder = (category) => {
            if (!category) {
              return
            }
            if (category.parent_id >= entities.category.categoriesRootCategoryId) {
              dispatch('single', { key: 'id', value: category.parent_id, setCurrentCategory: false, setCurrentCategoryPath: false }).then((sc) => { // TODO: move it to the server side for one requests OR cache in indexedDb
                if (!sc || sc.parent_id === sc.id) {
                  commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath)
                  EventBus.$emit('category-after-single', { category: mainCategory })
                  return resolve(mainCategory)
                }
                currentPath.unshift(sc)
                recurCatFinder(sc)
              }).catch(err => {
                Logger.error(err)()
                commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath) // this is the case when category is not binded to the root tree - for example 'Erin Recommends'
                resolve(mainCategory)
              })
            } else {
              commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath)
              EventBus.$emit('category-after-single', { category: mainCategory })
              resolve(mainCategory)
            }
          }
          if (typeof mainCategory !== 'undefined') {
            recurCatFinder(mainCategory) // TODO: Store breadcrumbs in IndexedDb for further usage to optimize speed?
          } else {
            reject(new Error('Category query returned empty result ' + key + ' = ' + value))
          }
        } else {
          EventBus.$emit('category-after-single', { category: mainCategory })
          resolve(mainCategory)
        }
      }

      let foundInLocalCache = false
      if (state.list.length > 0 && !skipCache) { // SSR - there were some issues with using localForage, so it's the reason to use local state instead, when possible
        const category = state.list.find((itm) => { return itm[key] === value })
        // Check if category exists in the store OR we have recursively reached Default category (id=1)
        if (category && value >= entities.category.categoriesRootCategoryId/** root category parent */) {
          foundInLocalCache = true
          setcat(null, category)
        }
      }
      if (!foundInLocalCache) {
        if (skipCache || isServer) {
          fetchCat({ key, value })
        } else {
          const catCollection = StorageManager.get('categories')
          // Check if category does not exist in the store AND we haven't recursively reached Default category (id=1)
          catCollection.getItem(entityKeyName(key, value), setcat)
        }
      }
    })
  },
  /**
   * Filter category products
   */
  products (context, { populateAggregations = false, filters = [], searchProductQuery, current = 0, perPage = 50, sort = '', includeFields = null, excludeFields = null, configuration = null, append = false, skipCache = false, cacheOnly = false }) {
    context.dispatch('setSearchOptions', {
      populateAggregations,
      filters,
      current,
      perPage,
      includeFields,
      excludeFields,
      configuration,
      append,
      sort
    })

    let prefetchGroupProducts = true
    if (entities.twoStageCaching && entities.optimize && !isServer && !rootStore.state.twoStageCachingDisabled) { // only client side, only when two stage caching enabled
      includeFields = entities.productListWithChildren.includeFields // we need configurable_children for filters to work
      excludeFields = entities.productListWithChildren.excludeFields
      prefetchGroupProducts = false
      Logger.log('Using two stage caching for performance optimization - executing first stage product pre-fetching')()
    } else {
      prefetchGroupProducts = true
      if (rootStore.state.twoStageCachingDisabled) {
        Logger.log('Two stage caching is disabled runtime because of no performance gain')()
      } else {
        Logger.log('Two stage caching is disabled by the config')()
      }
    }
    if (cacheOnly) {
      excludeFields = null
      includeFields = null
      Logger.log('Caching request only, no state update')()
    }
    const t0 = new Date().getTime()

    const precachedQuery = searchProductQuery
    const productPromise = rootStore.dispatch('product/list', {
      query: precachedQuery,
      start: current,
      size: perPage,
      excludeFields: excludeFields,
      includeFields: includeFields,
      configuration: configuration,
      append: append,
      sort: sort,
      updateState: !cacheOnly,
      prefetchGroupProducts: prefetchGroupProducts
    }).then((res) => {
      const t1 = new Date().getTime()
      rootStore.state.twoStageCachingDelta1 = t1 - t0

      const subloaders = []
      if (!res || (res.noresults)) {
        rootStore.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t('No products synchronized for this category. Please come back while online!'),
          action1: { label: i18n.t('OK') }
        })
        if (!append) rootStore.dispatch('product/reset')
        rootStore.state.product.list = { items: [] } // no products to show TODO: refactor to rootStore.state.category.reset() and rootStore.state.product.reset()
        // rootStore.state.category.filters = { color: [], size: [], price: [] }
        return []
      } else {
        if (products.filterUnavailableVariants && products.configurableChildrenStockPrefetchStatic) { // prefetch the stock items
          const skus = []
          let prefetchIndex = 0
          res.items.map(i => {
            if (products.configurableChildrenStockPrefetchStaticPrefetchCount > 0) {
              if (prefetchIndex > products.configurableChildrenStockPrefetchStaticPrefetchCount) return
            }
            skus.push(i.sku) // main product sku to be checked anyway
            if (i.type_id === 'configurable' && i.configurable_children && i.configurable_children.length > 0) {
              for (const confChild of i.configurable_children) {
                const cachedItem = context.rootState.stock.cache[confChild.id]
                if (typeof cachedItem === 'undefined' || cachedItem === null) {
                  skus.push(confChild.sku)
                }
              }
              prefetchIndex++
            }
          })
          for (const chunkItem of chunk(skus, 15)) {
            rootStore.dispatch('stock/list', { skus: chunkItem, skipCache }) // store it in the cache
          }
        }
        if (populateAggregations === true && res.aggregations) { // populate filter aggregates
          for (const attrToFilter of filters) { // fill out the filter options
            const filterOptions = []

            const uniqueFilterValues = new Set<string>()
            if (attrToFilter !== 'price') {
              if (res.aggregations['agg_terms_' + attrToFilter]) {
                let buckets = res.aggregations['agg_terms_' + attrToFilter].buckets
                if (res.aggregations['agg_terms_' + attrToFilter + '_options']) {
                  buckets = buckets.concat(res.aggregations['agg_terms_' + attrToFilter + '_options'].buckets)
                }

                for (const option of buckets) {
                  uniqueFilterValues.add(toString(option.key))
                }
              }

              uniqueFilterValues.forEach(key => {
                const label = optionLabel(rootStore.state.attribute, { attributeKey: attrToFilter, optionId: key })
                if (trim(label) !== '') { // is there any situation when label could be empty and we should still support it?
                  filterOptions.push({
                    id: key,
                    label: label
                  })
                }
              });
            } else { // special case is range filter for prices
              const storeView = currentStoreView()
              const currencySign = storeView.i18n.currencySign
              if (res.aggregations['agg_range_' + attrToFilter]) {
                let index = 0
                const count = res.aggregations['agg_range_' + attrToFilter].buckets.length
                for (const option of res.aggregations['agg_range_' + attrToFilter].buckets) {
                  filterOptions.push({
                    id: option.key,
                    from: option.from,
                    to: option.to,
                    label: (index === 0 || (index === count - 1)) ? (option.to ? '< ' + currencySign + option.to : '> ' + currencySign + option.from) : currencySign + option.from + (option.to ? ' - ' + option.to : '')// TODO: add better way for formatting, extract currency sign
                  })
                  index++
                }
              }
            }
            context.dispatch('addAvailableFilter', {
              key: attrToFilter,
              options: filterOptions
            })
          }
        }
      }
      return subloaders
    }).catch((err) => {
      Logger.error(err)()
      rootStore.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('No products synchronized for this category. Please come back while online!'),
        action1: { label: i18n.t('OK') }
      })
    })

    if (entities.twoStageCaching && entities.optimize && !isServer && !rootStore.state.twoStageCachingDisabled && !cacheOnly) { // second stage - request for caching entities; if cacheOnly set - the caching took place with the stage1 request!
      Logger.log('Using two stage caching for performance optimization - executing second stage product caching', 'category') // TODO: in this case we can pre-fetch products in advance getting more products than set by pageSize()
      rootStore.dispatch('product/list', {
        query: precachedQuery,
        start: current,
        size: perPage,
        excludeFields: null,
        includeFields: null,
        configuration: configuration,
        sort: sort,
        updateState: false, // not update the product listing - this request is only for caching
        prefetchGroupProducts: prefetchGroupProducts
      }).catch((err) => {
        Logger.info("Problem with second stage caching - couldn't store the data", 'category')()
        Logger.info(err, 'category')()
      }).then(() => {
        const t2 = new Date().getTime()
        rootStore.state.twoStageCachingDelta2 = t2 - t0
        Logger.log('Using two stage caching for performance optimization - Time comparison stage1 vs stage2' + rootStore.state.twoStageCachingDelta1 + rootStore.state.twoStageCachingDelta2, 'category')()
        if (rootStore.state.twoStageCachingDelta1 > rootStore.state.twoStageCachingDelta2) { // two stage caching is not making any good
          rootStore.state.twoStageCachingDisabled = true
          Logger.log('Disabling two stage caching', 'category')()
        }
      })
    }
    return productPromise
  },
  addAvailableFilter ({ commit }, { key, options } = {}) {
    if (key) commit(types.CATEGORY_ADD_AVAILABLE_FILTER, { key, options })
  },
  resetFilters (context) {
    context.commit(types.CATEGORY_REMOVE_FILTERS)
  },
  searchProductQuery (context, productQuery) {
    context.commit(types.CATEGORY_UPD_SEARCH_PRODUCT_QUERY, productQuery)
  },
  setSearchOptions ({ commit }, searchOptions) {
    commit(types.CATEGORY_SET_SEARCH_OPTIONS, searchOptions)
  },
  mergeSearchOptions ({ commit }, searchOptions) {
    commit(types.CATEGORY_MERGE_SEARCH_OPTIONS, searchOptions)
  },
  async loadCategoryProducts (
    {
      commit,
      dispatch,
      rootGetters,
      state
    },
    {
      route,
      category,
      pageSize = 50,
      start = 0,
      loadOnlyCounter = false
    } = {}
  ) {
    const isAdditionalLoading = state.isAdditionalLoading;
    // if (!isAdditionalLoading) {
    //   dispatch('setProductsLoading', true)
    // }
    const searchCategory = category || rootGetters['category-next/getCategoryFrom'](route.path) || {}
    const shopId = await getCurrentShopId()
    const categoryMappedFilters = rootGetters['category-extension/getFiltersMap'][searchCategory.id]
    const routerFiltersSource = route[products.routerFiltersSource]
    const areFiltersInQuery = !!Object.keys(routerFiltersSource).length

    if (!categoryMappedFilters && areFiltersInQuery) { // loading all filters only when some filters are currently chosen and category has no available filters yet
      await dispatch('category-extension/loadCategoryFilters', searchCategory, { root: true })
    }

    let searchQuery = rootGetters['category-extension/getCurrentFiltersFrom'](routerFiltersSource, categoryMappedFilters)
    if (areFiltersInQuery) {
      for (const routerFilter in routerFiltersSource) {
        if (routerFiltersSource.hasOwnProperty(routerFilter) && routerFilter === attributes.filterIsPromo.type) {
          searchQuery = {
            ...searchQuery,
            filters: {
              ...searchQuery.filters,
              attribute_code: routerFilter
            }
          }
        }
      }
    }
    const paramSearch = searchQuery.filters

    const filterQr = buildFilterProductsQuery(searchCategory, paramSearch, null, '', [], shopId)
    const filterAvailableQr = buildFilterAvailableQuery(searchCategory, null, [])

    const filterPrice = rootGetters['category-extension/getFilterPrice']

    if (searchQuery.filters.hasOwnProperty('price')) {
      if (Object.keys(filterPrice).length > 0) {
        filterQr.applyFilter({ key: `sqpp_data_${shopId}.sort_price`,
          value: {
            'gte': filterPrice.from,
            'lte': filterPrice.to
          },
          scope: 'default' })
      } else {
        filterQr.applyFilter({ key: `sqpp_data_${shopId}.sort_price`,
          value: {
            'gte': route[products.routerFiltersSource].price.split('-')[0],
            'lte': route[products.routerFiltersSource].price.split('-')[1]
          },
          scope: 'default' })
      }
    }

    filterQr.addAvailableFilter({ field: 'promotion_banner_ids', scope: 'catalog' })
      .addAvailableFilter({ field: 'price',
        scope: 'catalog',
        options: {
          'shop_id': shopId,
          'version': '2'
        }
      })
      .addAvailableFilter({
        field: 'has_promotion_in_stores',
        scope: 'catalog',
        options: {
          size: 10000
        }
      })
      .addAvailableFilter({
        field: 'markdown_id',
        scope: 'catalog'
      })

    const onlyInStock = searchQuery.hasOwnProperty('stock_shop')
    const onlyMarkdown = searchQuery.hasOwnProperty('markdown')

    const { items, perPage, total, aggregations, attributeMetadata } = await dispatch('product/findProducts', {
      query: filterQr,
      sort: searchQuery.sort || routerFiltersSource.sort || `${products.defaultSortBy.attribute}:${products.defaultSortBy.order}`,
      start,
      includeFields: loadOnlyCounter ? [] : modulesConfig.smallProduct.includeFields,
      excludeFields: loadOnlyCounter ? ['*'] : modulesConfig.smallProduct.excludeFields,
      skipLoadOptions: true,
      size: pageSize,
      onlyInStock: onlyInStock,
      onlyMarkdown: onlyMarkdown,
      loadDiscountedProducts: true,
      options: {
        populateRequestCacheTags: true,
        prefetchGroupProducts: false,
        setProductErrors: false,
        fallbackToDefaultWhenNoAvailable: true,
        assignProductConfiguration: false,
        separateSelectedVariant: false
      }
    }, { root: true })

    const toPromise = [
      dispatch('product/categoryProductsInStock', {
        query: filterAvailableQr
      }, { root: true })
    ]

    if (!loadOnlyCounter) {
      toPromise.push(
        dispatch('category-extension/loadAvailableFiltersFrom', {
          aggregations,
          attributeMetadata,
          category: category,
          filters: searchQuery.filters
        }, { root: true })
      )
    }

    if ((category.parent_id === entities.category.categoriesRootCategoryId) &&
      aggregations.hasOwnProperty('agg_terms_promotion_banner_ids.keyword')) {
      const ids = aggregations['agg_terms_promotion_banner_ids.keyword'].buckets.map(elem => elem.key)
      dispatch('setCategoryPromotionBannersIds', { categoryId: category.id, promotionBannerIds: ids })

      toPromise.push(
        dispatch('promoted/updateProductPromotionBanners', { promotionBannerIds: ids }, { root: true })
      )
    }

    const [available] = await Promise.all(toPromise)

    commit(types.AVAILABLE_PRODUCTS, available)

    if (!loadOnlyCounter) {
      if (isAdditionalLoading) {
        commit(types.ADD_PRODUCTS, items)
      } else {
        commit(types.SET_PRODUCTS, items)
      }
    }

    dispatch('ui/setPagingLoading', false, { root: true })

    // dispatch('setProductsLoading', false)
    commit(`category-next/${CATEGORY_SET_SEARCH_PRODUCTS_STATS}`, { perPage, start, total }, { root: true })
    if (!loadOnlyCounter) {
      commit(`category-extension/${SET_PRODUCT_AGGREGATION}`, aggregations, { root: true })
    }

    return items
  },
  setCategoryPromotionBannersIds ({ commit }, { categoryId, promotionBannerIds }) {
    commit(types.SET_CATEGORY_PROMOTION_BANNERS_IDS, { categoryId, promotionBannerIds })
  },
  async loadCategoriesCounts ({ commit }, { rootCategory }) {
    const shopId = await getCurrentShopId()
    const filterQr = buildFilterProductsQuery(rootCategory, {}, null, '', [], shopId)
    filterQr.addAvailableFilter({
      field: 'category_ids',
      scope: 'catalog',
      options: {
        size: 10000
      }
    })

    const { aggregations } = await quickSearchByQuery({
      query: filterQr,
      excludeFields: ['*']
    })

    const buckets = aggregations.agg_terms_category_ids.buckets
    const productCountsByCategory = buckets.reduce((acc, bucket) => {
      acc[bucket.key] = bucket.doc_count;
      return acc;
    }, {});
    commit(types.SET_PRODUCT_COUNTS_BY_CATEGORY, productCountsByCategory)
  },
  async setProductsLoading ({ commit }, isLoading) {
    commit(types.SET_PRODUCTS_IS_LOADING, isLoading)
  },
  async loadMore ({ commit }, value) {
    commit(types.SET_IS_ADDITIONAL_LOADING, value);
  },
  changeRouterFilterParameters (context, query) {
    router.push({ query: query })
  },
  async loadCategoryBreadcrumbs ({ dispatch, getters }, { category, currentRouteName, omitCurrent = false }) {
    if (!category) {
      return
    }

    const categoryHierarchyIds = category.parent_ids ? [...category.parent_ids, category.id] : _prepareCategoryPathIds(category)
    const categories = []
    categoryHierarchyIds.forEach((item) => {
      const category = getters.getCategoryById(Number(item))
      if (!Object.values(category).length) return;

      categories.push(category)
    })

    const sorted = []
    for (const id of categoryHierarchyIds) {
      if (id === entities.category.categoriesRootCategoryId.toString()) {
        continue;
      }

      const index = categories.findIndex(cat => cat.id.toString() === id)
      if (index >= 0 && (!omitCurrent || categories[index].id !== category.id)) {
        sorted.push(categories[index])
      }
    }

    await dispatch('breadcrumbs/set', {
      current: currentRouteName,
      routes: parseCategoryPath(sorted)
    }, { root: true })

    return sorted
  },
  setCategoryDescription ({ commit }, description = '') {
    commit(types.SET_CATEGORY_DESCRIPTION, description)
  },
  setSampleSpecialOffers ({ commit }, sampleSpecialOffers = []) {
    commit(types.SET_SAMPLE_SPECIAL_OFFERS, sampleSpecialOffers)
  }
}

export default actions
