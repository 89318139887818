import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { CartState } from '../types/CartState'
import { onlineHelper } from '@vue-storefront/core/helpers';
import calculateTotals from '../helpers/calculateTotals';
import sumBy from 'lodash/sumBy';

const getters: GetterTree<CartState, RootState> = {
  isEditMode (state) {
    return state.editMode !== null
  },
  getEditingProductId (state, getters) {
    return getters.isEditMode && state.editMode.productId
  },
  getSelectedOptions (state, getters) {
    return getters.isEditMode && state.editMode.selectedOptions
  },
  getEditingQty (state, getters) {
    return getters.isEditMode && state.editMode.qty
  },
  getCartStates: (state) => state.cartStates,
  getCartSQPP: (state) => state.cartSQPP,
  getProductStock: (state) => state.productStockResult,
  getProductComments: (state) => state.productComments,
  isCartHasExciseItems: (state, getters, rootState, rootGetters) => {
    const items = rootGetters['cart/getCartItems']
    return items.some((item) => item['is_18_plus'] === true)
  },
  getItemsQuantity: ({ cartItems }) => cartItems.length,
  getNonGiftItemsQuantityWithQTY: ({ cartItems }) => {
    const nonGiftItems = cartItems.length ? cartItems.filter(e => !e.gift?.isGift) : []
    return sumBy(nonGiftItems, p => p.packingtype || p.qty)
  },
  getItemsQuantityWithQTY: ({ cartItems }) => sumBy(cartItems, p => p.packingtype || p.qty),
  getTotals: ({ cartItems, platformTotalSegments }, getters) => (
    (platformTotalSegments && onlineHelper.isOnline)
      ? platformTotalSegments
      : calculateTotals(
        getters.getFirstShippingMethod,
        getters.getFirstPaymentMethod,
        cartItems
      )
  ),
  getCartTotal: (state, getters) => {
    const totals = getters.getTotals || []
    const couponDiscount = totals?.find(i => i.code === 'discount_coupon')?.value || 0
    const shipping = totals?.find(i => i.code === 'shipping')?.value || 0
    const grandTotals = totals?.find(i => i.code === 'grand_total')?.value || 0

    return grandTotals - shipping + couponDiscount
  },
  canUpdateMethods: (state, getters) => getters.isCartSyncEnabled,
  canSyncTotals: (state, getters) => getters.isTotalsSyncEnabled,
  getSkippedRules: (state) => (
    (state?.platformTotals?.extension_attributes?.skipped_rules || []).filter(i => !!i?.description)
  ),
  getDiscounts: (state) => (
    (state?.platformTotals?.extension_attributes?.discount_breakdown || [])
  ),
  lastPull: (state) => state.lastPull,
  revertRule: (state) => state.revertRule
}

export default getters
